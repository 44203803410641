const popupLinks = document.querySelectorAll('.popup-link');
const body = document.querySelector('body');
const lockPadding = document.querySelectorAll('.lock-padding');
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import 'jquery-mask-plugin';

window.$ = require('jquery');

let unlock = true;
const timeout = 100;

function preventDefault(e) {
    e.preventDefault();
}

console.log(popupLinks)
if (popupLinks.length > 0) {
    for (let index = 0; index < popupLinks.length; index++) {
        const popupLink = popupLinks[index];
        popupLink.addEventListener('click', function (e) {
            const popupName = popupLink.getAttribute('href').replace('#', '');
            const popupPosition = $(popupLink).attr('data-pp');
            const sport = $(popupLink).attr('data-sport');
            const pack = $(popupLink).attr('data-package');
            const curentPopup = document.getElementById(popupName);
            popupOpen(curentPopup, popupPosition, sport, pack);
            e.preventDefault();
        });
        popupLink.addEventListener('pointermove', preventDefault);
    }
}


const popupCloseIcon = document.querySelectorAll('.close-popup');
if (popupCloseIcon.length > 0) {
    for (let index = 0; index < popupCloseIcon.length; index++) {
        const el = popupCloseIcon[index];
        el.addEventListener('click', function (e) {
            popupClose(el.closest('.popup'));
            e.preventDefault();
        });
    }
}

function excludeCountries() {
    const toExclude = document.querySelector('#iti-0__country-listbox');
    bodyLock(toExclude);
}

function popupOpen(curentPopup, popupPosition,  sport, pack) {
    if (curentPopup && unlock) {
        const popupActive = document.querySelector('.popup.open');
        if (popupActive) {
            popupClose(popupActive, false);
        }

        curentPopup.classList.add('open');
        bodyLock(curentPopup);
        $(curentPopup).find('form').attr('data-formName', popupPosition);
        $(curentPopup).find('form').attr('data-sport', sport || null);
        $(curentPopup).find('form').attr('data-pack', pack || null);
        const submitButton =$(curentPopup).find('#submit-button');
        submitButton.attr('href', `https://team404.online/lead_provider/lead?source=team404.online&site=team404.online&site_form=${popupPosition}&site_language=${locale}&utm_source=site&sport=${sport}&package=${pack}`)
        submitButton.on('click', (e)=> {
            popupClose(e.target.closest('.popup'))
        });
        if (curentPopup.getAttribute('id') === 'popup-order') {
            document.querySelector('.iti__flag-container').addEventListener('click', excludeCountries);
        }
        curentPopup.addEventListener('click', function (e) {
            if (!e.target.closest('.popup__content')) {
                popupClose(e.target.closest('.popup'));
            }
        });
    }
}

function popupClose(popupActive, doUnlock = true) {
    // if (unlock) {
    popupActive.classList.remove('open');
    // enableBodyScroll(curentPopup);

    bodyUnLock(popupActive);

    // }
}

let scrollY; // we'll store the scroll position here
function bodyLock(popup) {
    scrollY = window.scrollY;

    const lockPaddingValue = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px';

    if (lockPadding.length > 0) {
        for (let index = 0; index < lockPadding.length; index++) {
            const el = lockPadding[index];
            el.style.paddingRight = lockPaddingValue;
        }
    }
    body.style.paddingRight = lockPaddingValue;

    disableBodyScroll(popup);
}

function bodyUnLock(popup) {
    setTimeout(function () {
        if (lockPadding.length > 0) {
            for (let index = 0; index < lockPadding.length; index++) {
                const el = lockPadding[index];
                el.style.paddingRight = '0px';
            }
        }
        body.style.paddingRight = '0px';
    }, timeout);

    clearAllBodyScrollLocks();
    // // restore scroll position
    window.scrollTo(0, scrollY);
    unlock = false;
    setTimeout(function () {
        unlock = true;
    }, timeout);
}

document.addEventListener('keydown', function (e) {
    if (e.which === 27) {
        const popupActive = document.querySelector('.popup.open');
        popupClose(popupActive);
    }
});

(function () {
    if (!Element.prototype.closest) {
        Element.prototype.closest = function (css) {
            var node = this;
            while (node) {
                if (node.matches(css)) return node;
                else node = node.parentElement;
            }
            return null;
        };
    }
})();
(function () {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
    }
})();

//Phone validation
const locale = document.documentElement.lang;
const loadingTexts = {
    uk: 'Завантаження...',
    en: 'Loading...',
    ru: 'Загрузка...',
};
const submitTexts = {
    uk: 'Відправити',
    en: 'Submit',
    ru: 'Отправить',
};
const errorFillAvailFields = {
    uk: 'Будь ласка, заповніть усі необхідні поля',
    en: 'Please fill out all required fields',
    ru: 'Пожалуйста, заполните все обязательные поля',
};

const errorEnterValidPhoneFields = {
    uk: 'Будь ласка, введіть дійсний номер телефону',
    en: 'Please enter valid phone number',
    ru: 'Пожалуйста, введите правильный номер телефона',
};

const successfullySubmittedText = {
    uk: 'Форма успішно надіслана!',
    en: 'Form submitted successfully!',
    ru: 'Форма успешно отправлена!',
};

const errorSubmittedText = {
    uk: 'Щось пішло не так, спробуйте ще раз',
    en: 'Something went wrong, please try again',
    ru: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз',
};

//vars
const input = document.querySelector('#phone');
const form = document.getElementById('form');
const nameInput = document.getElementById('form-name');
const errorMessage = document.getElementById('form-error-message');
const submitButton = document.getElementById('submit-button');
const popupForm = document.getElementById('popup-order');

//form submit button state fn

function setLoadingState() {
    submitButton.disabled = true;
    submitButton.value = loadingTexts[locale];
}

function resetButton() {
    submitButton.disabled = false;
    submitButton.value = submitTexts[locale];
}

//phone input validations
var phoneInputID = '#phone';

let iti = intlTelInput(input, {
    formatOnDisplay: true,
    hiddenInput: 'full_number',
    initialCountry: 'UA',
    separateDialCode: true,
    excludeCountries: ['RU'],
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js',
});

$(phoneInputID).on('countrychange', function (event) {
    // Get the selected country data to know which country is selected.
    var selectedCountryData = iti.getSelectedCountryData();
    // Get an example number for the selected country to use as placeholder.

    let newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL);
    // Reset the phone number input.
    iti.setNumber('');
    let mask = newPlaceholder.replace(/[1-9]/g, '0');

    $(this).mask(mask);
});

iti.promise.then(function () {
    $(phoneInputID).trigger('countrychange');
});

//form validation and submition

function onSuccess() {
    // Get the toast element and set its text content
    form.reset();
    popupForm.classList.toggle('open');
    bodyUnLock(popupForm);
    const toast = document.getElementById('toast');
    toast.textContent = successfullySubmittedText[locale];

    // Add a class to the toast to show it
    toast.classList.add('show');

    // After 3 seconds, remove the show class to hide the toast
    setTimeout(() => {
        toast.classList.remove('show');
    }, 3000);
}

function formSubmit(formData) {
    setLoadingState();

    // Create a new FormData object
    const formDataWithPage = new FormData();

    // Append the existing form data
    for (const [key, value] of formData.entries()) {
        formDataWithPage.append(key, value);
    }

    // Append the page field with the current page path
    formDataWithPage.append('page', document.location.pathname);

    //TODO: paste here correct form submit link
    fetch('SUBMISSION_LINK', {
        method: 'POST',
        body: formDataWithPage,
        headers: {
            Accept: 'application/json',
        },
    })
        .then(response => {
            resetButton();
            console.log(response);
            onSuccess();
        })
        .catch(error => {
            resetButton();
            console.log(error);
        });
}

form.addEventListener('submit', event => {
    event.preventDefault();
    errorMessage.textContent = '';
    const name = form.elements['name'].value;
    const phone = form.elements['phone'].value;

    let selectedCountryData = iti.getSelectedCountryData();

    if (!name || !phone) {
        errorMessage.textContent = errorFillAvailFields[locale];

        if (!name) document.getElementById('form-name').classList.add('input-error');
        if (!phone) document.getElementById('phone').classList.add('input-error');
        return;
    }

    let numberWithCode = intlTelInputUtils.formatNumber(phone, selectedCountryData.iso2, intlTelInputUtils.numberFormat.INTERNATIONAL);
    let isPhoneValid = intlTelInputUtils.isValidNumber(numberWithCode, selectedCountryData.iso2);

    if (!isPhoneValid) {
        errorMessage.textContent = errorEnterValidPhoneFields[locale];
        if (!phone) document.getElementById('phone').classList.add('input-error');
        return;
    }
    const formData = new FormData(form);
    formSubmit(formData);
});

//error message reset

input.addEventListener('focus', event => {
    if (errorMessage.textContent !== '') errorMessage.textContent = '';
    if (input.classList.contains('input-error')) document.getElementById('phone').classList.remove('input-error');
    if (nameInput.classList.contains('input-error')) document.getElementById('form-name').classList.remove('input-error');
});

nameInput.addEventListener('focus', event => {
    if (errorMessage.textContent !== '') errorMessage.textContent = '';
    if (nameInput.classList.contains('input-error')) document.getElementById('form-name').classList.remove('input-error');
    if (input.classList.contains('input-error')) document.getElementById('phone').classList.remove('input-error');
});
